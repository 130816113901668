import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet al principi subterrani, en forma d’ou inicialment, de 4-6 x 3-5 cm amb una mena d’arrel més o menys ramificada, de color blanc i fluix al tacte. El peridi s’obre i hi emergeix un peu blanc, esponjós i buit, acabat per una part cònica, de color verd olivaci i amb una olor repugnant que l’identifica. Pot mesurar fins a 20 cm d’altura. Les espores són de color groc pàl·lid, llises, un poc ovalades, de 3-5 x 1,5-2 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      